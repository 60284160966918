export const HOMEPICSDATA = "HOMEPICSDATA";
export const HEADANDFOOTERPICSDATA = "HEADANDFOOTERPICSDATA";
export const ABOUTSPICSDATA = "ABOUTSPICSDATA";
export const TERMSPICSDATA = "TERMSPICSDATA";
export const PRIVACYPICSDATA = "PRIVACYPICSDATA";
export const UNIVERSITYPICSDATA = "UNIVERSITYPICSDATA";
export const COUNTRYPICSDATA = "COUNTRYPICSDATA";
export const CONTACTPICSDATA = "CONTACTPICSDATA";
export const UPDATECONTACTPICSDATA = "UPDATECONTACTPICSDATA";
export const FAQPICSDATA = "FAQPICSDATA";
export const FAQCATEGORYPICSDATA = "FAQCATEGORYPICSDATA";
export const FAQLISTPICSDATA = "FAQLISTPICSDATA";

